import React from 'react'

// import Layout from '../components/layout'
// import SEO from '../components/seo'

const NotFoundPage = () => (
  <p>Whoops! 404</p>
)

export default NotFoundPage
